// profile related help texts
export const TrainerProfile = {
  professionalOverview:
    "Your elevator Pitch. Should be crafted by trainers in their own words and in the way they want to represent themselves. It should have a mention of some or all of the following - area of expertise (where you provide training and coaching services), key employers, education details, key clients, certifications, patents, awards. It should not contain details of the organization you are associated with.",
  personalWebsite:
    "Do you maintain your own website or Blog. Please share the details with us",
  trainingLaguage:
    "Can provide training in the langauge and can use to help participants connect the dots ",
  regionalLanguage:
    "Can speak and understand the langauge  but can't train in that language",
  professionalNetwork:
    "Youtube Channel, GitHub, Stackoverflow/Stackexchange, Hackerrank/Hackerearth score/profile, Kaggle, Anything else"
};
export const WorkExperince = {
  education:
    "At times, we get request for college trainings as well, so would help reach out"
};
export const SkillAndServices = {
  primarySkills:
    "Maximum 5 skills or competencies. Comma separated values. What is a Primary skill? : Skill on which you do most of your trainings, training is on that subject/skill rather than the skill being used as a supporting tool/small component of a training. You can go to the maximum depth on this skill, compared to other skills you do trainings on",
  targetAudincePrimary:
    "You might be delivering services for various target audiences, here we would like to know the primary one, where you deliver the most number of interventions",
  secondarySkills:
    "No limit on number of skills. Comma separated values.  What is a Secondary skill? : Skill on which you do trainings, but not quite often and do not have deep depth/grip on this subject/skill.",
  toolsAndMethodologies:
    "Maximum 10 Tools/Methodologies/Platforms. Comma separated values. You may not be providing training on these but you extensively use them while training on your primary and secondary skills e.g. a communication skills trainer may use storytelling technique in their training but not train on storytelling as such. A ML trainer may use Python for training on ML topics but may not provide trainings on Python alone. A DevOps trainer may use Azure Platform but do not provide specific trainings on Azure",
  repeatCustomberName:
    "Comma separated value. Repeat business means success and this data will be useful for us. If you prefer to give more details - like how many sessions, target audience, please do so. Customers (not vendor/partners), where you have got repeat business. If you are not comfortable sharing repeat customer names - use symbolic references (e.g. instead of saying Amazon - World' largest Ecommerce Company or Large Ecommerce Multinational)"
};
export const PaymentDetails = {
  minimumFees:
    "Fee, below which you don't see value in delivering trainings as it is not worth your time and opportunity cost",
  standardFees: "The fee you charge for most of your training interventions",
  maximumFees:
    "Maximum fee you have got in the market, either direct customers or your partners. This helps us understand how big the opportunity is in the market"
};
