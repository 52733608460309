<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col col="11">
        <div class="text-h5 font-weight-bold mx-3">
          Payment Detials
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <ProfileCard />
    </v-row>
    <v-card class="my-5" flat color="rgb(236,240,243)">
      <v-row>
      <v-col cols="6">
      <div class="text-h6 font-weight-bold mx-5 text--primary">
              Stage 1C 
      </div>
      </v-col>
      <v-col cols="5">
        <div class="text-h6 font-weight-bold  text--primary">
              Stage 2B 
      </div>
      </v-col>
      <v-col>
        <v-card-actions>
          <v-btn
            @click="enableEditMode"
            v-if="!editMode.paymentDetails"
            class="mx-3"
            color="primary"
            style="background-color:blue"
            >Edit</v-btn
          >
          <v-btn
            v-else-if="
              !paymentDetials.trainerBankDetials.id && editMode.paymentDetails
            "
            @click="createPaymentDetials"
            class="mx-3"
            color="primary"
            slot="activator"
            style="background-color:blue"
            :loading="loading"
            >Save</v-btn
          >
          <v-btn
            v-else
            @click="updatePaymentDetails"
            class="mx-3"
            color="primary"
            slot="activator"
            style="background-color:blue"
            :loading="loading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-col>
      </v-row>
      <v-card-text>
        <v-form v-model="valid" ref="paymentFrom">
          <v-row>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                Fee Charged For 1 hour session in INR
              </div>
            </v-col>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                Negotiated fee for 1 hour session in INR
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-row justify="space-around">
                  <v-col cols="3">
                    <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text"
                    >
                        Minimum fees <ToolTip :tooltipText="helptextsPaymentDetails.minimumFees"/>
                    </div>
                    <div class="text-h7 font-weight-bold text--primary" v-else>
                      Minimum fees <ToolTip :tooltipText="helptextsPaymentDetails.minimumFees"/>
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text ml-n7"
                    >
                        Standard fees <ToolTip :tooltipText="helptextsPaymentDetails.standardFees"/>
                    </div>
                    <div class="text-h7 font-weight-bold text--primary ml-n12" v-else>
                      Standard fees <ToolTip :tooltipText="helptextsPaymentDetails.standardFees"/>
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text ml-n7"
                    >
                        Maximum fees <ToolTip :tooltipText="helptextsPaymentDetails.maximumFees"/>
                    </div>
                    <div class="text-h7 font-weight-bold text--primary ml-n1" v-else>
                      Maximum fees  <ToolTip :tooltipText="helptextsPaymentDetails.maximumFees"/>
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="space-around">
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4 mx-6" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.TWO_HOUR_FEES
                          .trainerMinimumFess}}
                    </div>
                    <v-text-field v-else
                      :disabled="!editMode.paymentDetails"
                      class="inputPrice"
                      type="number"
                      v-model="
                        paymentDetials.trainerFees.TWO_HOUR_FEES
                          .trainerMinimumFess
                      "
                      label=""
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4 mx-7" v-if="!editMode.paymentDetails">
                      ₹ {{ paymentDetials.trainerFees.TWO_HOUR_FEES
                          .trainerStandardFess}}
                    </div>
                    <v-text-field v-else
                      label=""
                      class="inputPrice"
                      type="number"
                      :disabled="!editMode.paymentDetails"
                      v-model="
                        paymentDetials.trainerFees.TWO_HOUR_FEES
                          .trainerStandardFess
                      "
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4 mx-3" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.TWO_HOUR_FEES
                          .trainerMaximumFess}}
                    </div>
                    <v-text-field v-else
                      label=""
                      class="inputPrice"
                      :disabled="!editMode.paymentDetails"
                      type="number"
                      v-model="
                        paymentDetials.trainerFees.TWO_HOUR_FEES
                          .trainerMaximumFess
                      "
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <!-- <div class=" grey--text mx-4  font-weight-medium">
              ... Show fee change history
            </div> -->
                </v-row>
              </v-card>
            </v-col>
            <!-- neogitiated 2 hours -->
            <v-spacer></v-spacer>
            <v-col cols="6">
              <v-card flat>
                <v-row>
                  <v-col cols="5">
                    <div class="text-h7 font-weight-bold primary--text mx-2" v-if="!editMode.paymentDetails">
                      Negotiated Fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary mx-2" v-else>
                      Negotiated Fees
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <div class="text-h7 font-weight-light mx-2">
                        <div class="text-h7 font-weight-bold text--primary my-n4" v-if="!editMode.paymentDetails">
                      ₹ {{ paymentDetials.trainerFees.TWO_HOUR_FEES
                            .trainerNegotiatedFess}}
                    </div>
                      <v-text-field v-else
                        label=""
                        :disabled="!editMode.paymentDetails"
                        class="inputPrice"
                        type="number"
                        v-model="
                          paymentDetials.trainerFees.TWO_HOUR_FEES
                            .trainerNegotiatedFess
                        "
                        placeholder="0"
                        outlined
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <!-- <div class=" grey--text mx-4 font-weight-medium">
                    ... Show fee change history
                  </div> -->
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <!-- half day session -->
          <v-row>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                Fee Charged For half day session in INR
              </div>
            </v-col>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                Negotiated fee for half day session in INR
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-row justify="space-around">
                  <v-col cols="2">
                    <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text"
                    >
                        Minimum fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary" v-else>
                      Minimum fees
                    </div>
                  </v-col>
                  <v-col cols="2">
                     <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text ml-n7"
                    >
                        Standard fess
                    </div>
                    <div class="text-h7 font-weight-bold text--primary ml-n7" v-else>
                      Standard fess
                    </div>
                  </v-col>
                  <v-col cols="2">
                   <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text ml-n7"
                    >
                        Maximum fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary ml-n1" v-else>
                      Maximum fees
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="space-around">
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4 mx-6" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.HALF_DAY_FEES
                          .trainerMinimumFess}}
                    </div>
                    <v-text-field v-else
                      :disabled="!editMode.paymentDetails"
                      class="inputPrice"
                      type="number"
                      v-model="
                        paymentDetials.trainerFees.HALF_DAY_FEES
                          .trainerMinimumFess
                      "
                      label=""
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.HALF_DAY_FEES
                          .trainerStandardFess}}
                    </div>
                    <v-text-field v-else
                      label=""
                      :disabled="!editMode.paymentDetails"
                      class="inputPrice"
                      type="number"
                      v-model="paymentDetials.trainerFees.HALF_DAY_FEES.trainerStandardFess"
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.HALF_DAY_FEES
                          .trainerMaximumFess}}
                    </div>
                    <v-text-field v-else
                      label=""
                      :disabled="!editMode.paymentDetails"
                      class="inputPrice"
                      type="number"
                      v-model="
                        paymentDetials.trainerFees.HALF_DAY_FEES
                          .trainerMaximumFess
                      "
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <!-- <div class=" grey--text mx-4  font-weight-medium">
              ... Show fee change history
            </div> -->
                </v-row>
              </v-card>
            </v-col>
            <!-- <FullFees/> -->
            <v-spacer></v-spacer>
            <v-col cols="6">
              <v-card flat>
                <v-row>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold primary--text mx-2" v-if="!editMode.paymentDetails">
                      Negotiated Fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary mx-2" v-else>
                      Negotiated Fees
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <div class="text-h7 font-weight-light mx-2">
                       <div class="text-h7 font-weight-bold text--primary my-n4" v-if="!editMode.paymentDetails">
                      ₹ {{ paymentDetials.trainerFees.HALF_DAY_FEES
                            .trainerNegotiatedFess}}
                    </div>
                      <v-text-field v-else
                        label=""
                        :disabled="!editMode.paymentDetails"
                        class="inputPrice"
                        type="number"
                        v-model="
                          paymentDetials.trainerFees.HALF_DAY_FEES
                            .trainerNegotiatedFess
                        "
                        placeholder="0"
                        outlined
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <!-- <div class=" grey--text mx-4 font-weight-medium">
                    ... Show fee change history
                  </div> -->
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                Fee Charged for full day session in INR
              </div>
            </v-col>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                Negotiated fee for full day session in INR
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-row justify="space-around">
                  <v-col cols="2">
                     <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text"
                    >
                        Minimum fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary" v-else>
                      Minimum fees
                    </div>
                  </v-col>
                  <v-col cols="2">
                     <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text ml-n7"
                    >
                        Standard fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary ml-n7" v-else>
                      Standard fees
                    </div>
                  </v-col>
                  <v-col cols="2">
                   <div
                      v-if="!editMode.paymentDetails"
                      class="text-h7 font-weight-bold primary--text ml-n7"
                    >
                        Maximum fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary ml-n1" v-else>
                      Maximum fees
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="space-around">
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4 mx-6" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.FULL_DAY_FEES
                          .trainerMinimumFess}}
                    </div>
                    <v-text-field
                    v-else
                      :disabled="!editMode.paymentDetails"
                      label=""
                      class="inputPrice"
                      type="number"
                      v-model="
                        paymentDetials.trainerFees.FULL_DAY_FEES
                          .trainerMinimumFess
                      "
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold text--primary my-n4" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.FULL_DAY_FEES
                          .trainerStandardFess}}
                    </div>
                    <v-text-field v-else
                      :disabled="!editMode.paymentDetails"
                      label=""
                      class="inputPrice"
                      type="number"
                      v-model="
                        paymentDetials.trainerFees.FULL_DAY_FEES
                          .trainerStandardFess
                      "
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                     <div class="text-h7 font-weight-bold text--primary my-n4" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.FULL_DAY_FEES
                          .trainerMaximumFess}}
                    </div>
                    <v-text-field v-else
                      :disabled="!editMode.paymentDetails"
                      label=""
                      class="inputPrice"
                      type="number"
                      v-model="
                        paymentDetials.trainerFees.FULL_DAY_FEES
                          .trainerMaximumFess
                      "
                      placeholder="0"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <!-- <div class=" grey--text mx-4  font-weight-medium">
                    ... Show fee change history
                  </div> -->
                </v-row>
              </v-card>
            </v-col>
            <!-- <FullFees/> -->
            <v-spacer></v-spacer>
            <v-col cols="6">
              <v-card flat>
                <v-row>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold primary--text mx-2" v-if="!editMode.paymentDetails">
                      Negotiated Fees
                    </div>
                    <div class="text-h7 font-weight-bold text--primary mx-2" v-else>
                      Negotiated Fees
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- </v-row>
                <v-row> -->
                  <v-col cols="4">
                    <div class="text-h7 font-weight-light mx-2">
                      <div class="text-h7 font-weight-bold text--primary my-n4" v-if="!editMode.paymentDetails">
                      ₹ {{paymentDetials.trainerFees.FULL_DAY_FEES
                            .trainerNegotiatedFess}}
                    </div>
                      <v-text-field v-else
                        :disabled="!editMode.paymentDetails"
                        label=""
                        class="inputPrice"
                        type="number"
                        v-model="
                          paymentDetials.trainerFees.FULL_DAY_FEES
                            .trainerNegotiatedFess
                        "
                        placeholder="0"
                        outlined
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <!-- <div class=" grey--text mx-4 font-weight-medium">
                    ... Show fee change history
                  </div> -->
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <a class="text-h6 font-weight-bold  blue--text"  @click="routeTo">
            Click here to update trainer refrence data
          </a> 
          <div class="text-h6 font-weight-bold  text--primary">
              Stage 3A 
            </div>
          <v-row>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                Pan Details
              </div>
            </v-col>
            <v-col col="6">
              <!-- <div class="text-h6 font-weight-bold mx-2">
                Admin Activity
              </div> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                  Pan card number
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Pan card number
                </div>
                <v-col cols="8">
                  <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerPanDetials.panNumber}}
                    </div>
                  <v-text-field v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="paymentDetials.trainerPanDetials.panNumber"
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                  PAN card attachment
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  PAN card attachment
                </div>
                <!-- <div
                  class="text-h7 font-weight-light mx-4"
                  v-if="editMode.paymentDetails"
                >
                  PAN card attachment
                </div> -->
                <div class="pl-5" v-if="editMode.paymentDetails">
                  <UploadDoc type="PAN_CARD" />
                </div>
                <div v-if="paymentDetials.trainerPanDetials.panFileUrl != ''" class="text-h7 font-weight-bold mx-3 my-n5">
                  <v-row>
                    <v-col >
                      <a @click="downLoadFile('PAN_CARD')">
                        {{
                          getFileName(
                            paymentDetials.trainerPanDetials.panFileUrl
                          )
                        }}
                      </a>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <!-- <v-card flat>
                <v-row>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold mx-4">
                      Verified checkbox
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="checkbox"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col col="6">
              <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
                GST details
              </div>
            </v-col>
            <v-col col="6">
              <!-- <div class="text-h6 font-weight-bold mx-2">
                Admin Activity
              </div> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                  Gst registration number
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Gst registration number
                </div>
                <v-col cols="8">
                  <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerGst.gstRegistrationNumber}}
                    </div>
                  <v-text-field
                  v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="paymentDetials.trainerGst.gstRegistrationNumber"
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="paymentDetials.trainerGst.gstCertificateUrl &&!editMode.paymentDetails">
                   GST certificate attachment
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else-if="editMode.paymentDetails">
                  GST certificate attachment
                </div>
                <!-- <div
                  class="text-h7 font-weight-light mx-4"
                  v-if="editMode.paymentDetails"
                >
                  GST Certificate Attachment
                </div> -->
                <div class="pl-5" v-if="editMode.paymentDetails">
                  <UploadDoc type="GST" />
                </div>
                <div v-if="paymentDetials.trainerGst.gstCertificateUrl != ''">
                  <v-row class="text-h7 font-weight-bold mx-1">
                    <v-col>
                      <a @click="downLoadFile('GST')">
                        {{
                          getFileName(
                            paymentDetials.trainerGst.gstCertificateUrl
                          )
                        }}
                      </a>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <!-- <v-card flat>
                <v-row>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold mx-4">
                      Verified checkbox
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="checkbox"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card> -->
            </v-col>
          </v-row>
          <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
            {{ bankTitle }}
          </div>
          <!-- <Title :primaryTitle="bankTitle" secondaryTitle="" /> -->
          <v-row>
            <v-col cols="6">
              <v-card flat>
                 <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                  Account number
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Account number
                </div>
                <v-col cols="8">
                  <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerBankDetials.accountNumber}}
                    </div>
                  <v-text-field
                  v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="paymentDetials.trainerBankDetials.accountNumber"
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                 <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                  Account name
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Account name
                </div>
                <v-col cols="8">
                  <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerBankDetials.accountName}}
                    </div>
                  <v-text-field
                  v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="paymentDetials.trainerBankDetials.accountName"
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                 <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                   Bank name
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Bank name
                </div>
                <v-col cols="8">
                  <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerBankDetials.bankName}}
                    </div>
                  <v-text-field
                  v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="paymentDetials.trainerBankDetials.bankName"
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                 <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                   Ifsc code
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Ifsc Code
                </div>
                <v-col cols="8">
                  <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerBankDetials.ifscCode}}
                    </div>
                  <v-text-field
                  v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="paymentDetials.trainerBankDetials.ifscCode"
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="paymentDetials.trainerBankDetials.cancelledChequeUrl != '' &&!editMode.paymentDetails">
                   Cancelled cheque leaf attachment
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else-if="editMode.paymentDetails">
                  Cancelled cheque leaf attachment
                </div>
                <div class="pl-5" v-if="editMode.paymentDetails">
                  <UploadDoc type="BANK_DETAILS" />
                </div>
                <div
                  v-if="paymentDetials.trainerBankDetials.cancelledChequeUrl != ''">
                  <v-row class="text-h7 font-weight-bold mx-1 my-n1">
                    <!-- <v-col>
                      <div class="text-h7 font-weight-light mx-4">
                        Cancelled Cheque Link
                      </div>
                    </v-col> -->
                    <v-col>
                      <a @click="downLoadFile('BANK_DETAILS')">
                        {{
                          getFileName(
                            paymentDetials.trainerBankDetials.cancelledChequeUrl
                          )
                        }}
                      </a>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <!-- <v-card flat>
                <v-row>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold mx-4">
                      Verified checkbox
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="checkbox"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card> -->
            </v-col>
          </v-row>
          <v-row>
            <div :class="!editMode.paymentDetails ? viewMode : editModeClass">
              Extra Information if enterpreneur
            </div>
            <!-- <Title primaryTitle="Extra Information if enterpreneur" /> -->
          </v-row>
          <v-row>
            <!-- <Title primaryTitle="Company pan detials" /> -->
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                   Company pan number
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Company pan number
                </div>
                <!-- <div class="text-h6 font-weight-blold mx-4 my-1">
                  Company pan number
                </div> -->
                <v-col>
                  <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerCompanyDetails.companyPanNumber}}
                    </div>
                  <v-text-field
                  v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="
                      paymentDetials.trainerCompanyDetails.companyPanNumber
                    "
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="paymentDetials.trainerCompanyDetails.companyPanFileUrl &&!editMode.paymentDetails">
                   Company registration certificate
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else-if="editMode.paymentDetails">
                  Company registration certificate
                </div>
                <!-- <div
                  class="text-h6 font-weight-blold mx-4 my-1"
                  v-if="editMode.paymentDetails"
                >
                  Company certificate
                </div> -->
                <div class="pl-5" v-if="editMode.paymentDetails">
                  <UploadDoc type="COMPANY_PAN" />
                </div>
                <div
                  v-if="paymentDetials.trainerCompanyDetails.companyPanFileUrl != ''">
                  <v-row class="text-h7 font-weight-bold  mx-1 my-n1">
                    <!-- <v-col>
                      <div class="text-h7 font-weight-light mx-4">
                        COMPANY PAN Link
                      </div>
                    </v-col> -->
                    <v-col>
                      <a @click="downLoadFile('COMPANY_PAN')">
                        {{
                          getFileName(
                            paymentDetials.trainerCompanyDetails
                              .companyPanFileUrl
                          )
                        }}
                      </a>
                    </v-col>
                  </v-row>
                </div>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                   Msme certificate number
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Msme certificate number
                </div>
                <v-col>
                   <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerCompanyDetails
                        .msmeRegistrationNumber}}
                    </div>
                  <v-text-field
                  v-else
                    :disabled="!editMode.paymentDetails"
                    label=""
                    v-model="
                      paymentDetials.trainerCompanyDetails
                        .msmeRegistrationNumber
                    "
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if=" paymentDetials.trainerCompanyDetails.msmeCertificateUrl !=
                      '' &&!editMode.paymentDetails">
                   Msme registration certificate
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else-if="editMode.paymentDetails">
                  Msme Registration certificate
                </div>
                <div class="pl-5" v-if="editMode.paymentDetails">
                  <UploadDoc type="COMPANY_MSME" />
                </div>
                <div
                  v-if="paymentDetials.trainerCompanyDetails.msmeCertificateUrl !=''">
                  <v-row class="text-h7 font-weight-bold  mx-1 my-n1">
                    <!-- <v-col>
                      <div class="text-h7 font-weight-light mx-4">
                        COMPANY PAN Link
                      </div>
                    </v-col> -->
                    <v-col>
                      <a @click="downLoadFile('COMPANY_MSME')">
                        {{
                          getFileName(
                            paymentDetials.trainerCompanyDetails
                              .msmeCertificateUrl
                          )
                        }}
                      </a>
                    </v-col>
                  </v-row>
                </div>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                   Registered entity name
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Registered entity name
                </div>
                <v-col>
                   <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerCompanyDetails.registeredEntity}}
                    </div>
                  <v-text-field
                  v-else
                    :disabled="!editMode.paymentDetails"
                    label=""
                    v-model="
                      paymentDetials.trainerCompanyDetails.registeredEntity
                    "
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
                <div  class="text-h6 font-weight-bold primary--text mx-1 my-2" v-if="!editMode.paymentDetails">
                   Registered entity address
                </div>
                <div class="text-h6 font-weight-bold text--primary mx-1 my-2" v-else>
                  Registered entity address
                </div>
                <v-col>
                   <div class="text-h7 font-weight-bold text--primary my-n4 mx-n1" v-if="!editMode.paymentDetails">
                    {{ paymentDetials.trainerCompanyDetails
                        .registeredEntityAddress}}
                    </div>
                  <v-text-field
                  v-else
                    label=""
                    :disabled="!editMode.paymentDetails"
                    v-model="
                      paymentDetials.trainerCompanyDetails
                        .registeredEntityAddress
                    "
                    placeholder="Type here..."
                    outlined
                  ></v-text-field>
                </v-col>
              </v-card>
            </v-col>
            <v-col cols="6">
              <!-- <v-card flat>
                <v-row>
                  <v-col cols="3">
                    <div class="text-h7 font-weight-bold mx-4">
                      Verified checkbox
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="checkbox"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card> -->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div :class="editMode.paymentDetails?editModeClass:viewMode">
             Admin comments
    </div>
    <AdminComments :showAddButton="editMode.paymentDetails" type="PAYMENT_DETAILS" />
  </v-container>
</template>

<script>
import AdminComments from "../../../components/AdminComments/AdminComments.vue"
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import ProfileCard from "../../../components/ProfileCard/ProfileCard.vue";
//import Title from "../../../components/Title/title.vue";
import UploadDoc from "../../../components/uploadDoc.vue";
import ToolTip from '../../../components/Tooltip/tooltip.vue'
import {PaymentDetails} from '../../../data/HelpTexts'
import { mapState } from "vuex";
export default {
  name: "PaymentDetials",
  components: {
    SecondaryNavigation,
    ProfileCard,
    AdminComments,
    //Title,
    UploadDoc,
    ToolTip
    //  FullFees
  },
  computed: {
    ...mapState("trainerProfile", {
      paymentDetials: "paymentDetials",
      loading: "loading",
      profile: "profile",
      editMode: "editMode"
    })
  },
  created() {
    console.log("payemnt detials  ----", this.paymentDetials);
    this.$store.dispatch(
      "trainerProfile/getPaymentDetails",
      this.profile.trainerId
    );
  },
  data() {
    return {
      helptextsPaymentDetails:PaymentDetails,
      valid: false,
      checkbox: true,
      bankTitle: "Bank Details",
      adminCommnets: "Admin Activity",
      editModeClass: "text-h6 text--primary font-weight-bold mx-2",
      viewMode: "text-h6 primary--text font-weight-bold mx-2"
    };
  },
  methods: {
    createPaymentDetials() {
      // add trainer id to all the keys
      let modfiedPaymentDetails = this.paymentDetials;
      modfiedPaymentDetails.trainerFees.TWO_HOUR_FEES.trainerId = this.profile.trainerId;
      modfiedPaymentDetails.trainerFees.HALF_DAY_FEES.trainerId = this.profile.trainerId;
      modfiedPaymentDetails.trainerFees.FULL_DAY_FEES.trainerId = this.profile.trainerId;
      modfiedPaymentDetails.trainerBankDetials.trainerId = this.profile.trainerId;
      modfiedPaymentDetails.trainerCompanyDetails.trainerId = this.profile.trainerId;
      modfiedPaymentDetails.trainerPanDetials.trainerId = this.profile.trainerId;
      modfiedPaymentDetails.trainerGst.trainerId = this.profile.trainerId;

      this.$store.dispatch(
        "trainerProfile/createPaymentDetials",
        modfiedPaymentDetails
      );
      console.log("payment deitals ---", modfiedPaymentDetails);
    },
    downLoadFile(type) {
      console.log(type);
      this.$store.dispatch("trainerProfile/downloadAFile", {type:type});
    },
    updatePaymentDetails() {
      this.$store.dispatch(
        "trainerProfile/updatePaymentDetails",
        this.paymentDetials
      );
    },
  async  enableEditMode() {
      let editMode =await this.$store.dispatch(
        "trainerProfile/changeEditMode",
        "PAYMENT_DETIALS"
      );
      console.log("edmit mode return ", editMode);
    },
    getFileName(s3Name) {
      if(s3Name){
    let splitArray = s3Name.split("/");
      splitArray = splitArray[splitArray.length - 1];
      splitArray = splitArray.split("_");
      splitArray = splitArray[splitArray.length - 1];
      return splitArray;
      }else{
        return null;
      }
    },
    routeTo(){
      this.$router.push('/pages/refrences');
    }
  }
};
</script>
<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
